body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /* overflow: hidden; */
}



/* @media (min-width: 576px) {
  .Graphs{
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .Graphs {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .Graphs {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .body {
    max-width: 1140px;
  }
} */



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.man-radio-button {
  display: flex;
  /* align-items: center; */
  margin: 7px;
}
.man-radio-button-group fieldset {
  min-width: 0;
  padding: 0px;
  border: 0;
  margin: 0px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.man-radio-button-group fieldset man-radio-button:not(:last-child) {
  margin-bottom: auto;
}

.js-plotly-plot{
  position: relative;
  display: inline-block;
  border-radius: 10px;
  /* box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1); */

}

.headers{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid rgba(234, 237, 243, 1);
  height: 85px;
  padding: 0px 24px;
  border-width: 0px 0px 2px;
}

.logo-container {
  display: flex;
  align-items: center;
  width: 320px;
  height: 47px;
}

.logo-title{
  font-size: 15px;
  padding: 28px;
}

.nav-items{
  border: 1px;
  display: flex;
  flex-direction: row;

}

.nav-items > ul{
  display: flex;
  list-style-type: none;
  justify-content: center;

}


.card-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  /* box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1); */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  padding: 0px;
}

.nav-items > ul > li{
  margin-left: 5px;
  font-size: 14px;
  padding: 20px;
  margin-right: 5px;
  font-family: 'Man Europe Condensed';
  line-height: 15.6px;
  /* background-color: red; */
}

.main-content{
  display: flex;
  flex-direction: row;
  height: 222vh;
  width: 100%;
  background-color: rgb(248, 248, 248);
}
.firstcard {
  margin: 5px;
  border: 1px solid rgba(234, 237, 243, 1);
  /* flex: 1 0 calc(14.28% - 10px);  */
  /* overflow: overlay; */
  height: 250px;

}

.firstcard:last-child{
  flex:100%; /* Make the last card occupy the full width */
}

.card_ride:last-child{
  flex:100%; /* Make the last card occupy the full width */
}

.firstcard_charging {
  margin: 5px;
  border: 1px solid rgba(234, 237, 243, 1);
  /* flex: 1 0 calc(14.28% - 10px);  */
  overflow: overlay;

}

.man-logovv1-card {
  position: relative;
  top: -35px;
  right: -332px;
  height: 20px;
}

.firstcard1{
  margin-bottom: 10px;
  margin-top: 46px;
}

.graph_div{
  display: flex;
    /* width: 100px; */
    justify-content: flex-end;
    align-content: space-around;
    flex-wrap: nowrap;
    /* align-items: stretch; */
}

.card_battery {
  flex: 1 1 calc(33.33% - 20px);
  box-sizing: border-box;
  margin: 5px;
  height: 100%; /* Set a fixed height */
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 10px;
}

.card_ride{
  /* flex: 1 1 calc(50% - 10px); */

  box-sizing: border-box;
  margin: 5px;
  height: 100%; /* Set a fixed height */
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 10px;
}






.card {
  border: 1px solid rgba(234, 237, 243, 1);
  padding: 15px;
  margin: 10px;
  width: 37vw;
  text-align: center;
  background-color: white;
  height: 120px;
  /* box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
}

.Graphs {
  display: flex;
  flex-direction: column;
  margin-left: 54px;
  width: 80vw;
}
@media (max-width: 600px) {
  .Graphs {
    margin-left: 20px;
    width: 80vw;

    /* Adjust other styles as needed */
  }
}


.MAN_info{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;
}

.MAN_info_text {
  margin-left: 5px;
  font-size: 16px;
}


.man-label > man-text{
  font-size: small;
}

.man-radio-button__control {
  width: 18px;
  height: 18px;
}
.sidebar{
  height: 222vh;
  width: 320px;
  padding: 20px;
  gap: 16px;
  color: white;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.man-radio-button__control:checked::before {
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -2px;
  display: block;
  width: var(--man-cp-radio-button-radio-sizing);
  height: var(--man-cp-radio-button-radio-sizing);
  border-radius: var(--man-cp-radio-button-border-radius);
  margin: 4px;
  background-color: var(--man-cp-radio-button-radio-background-default);
  content: "";
}

.update_img{
    background-color: white;
    border: 0px;
    float: right;
    position: relative;
    right: -84px;
}

.sidebar-title{
  width: 263px;
  height: 42px;
  display: flex;
  /* justify-content: space-between; */
}
.filter-title{

  color: rgba(48, 60, 73, 1);
}



.graph-text{
  padding: 10px;
}

.tabs1{
  padding: 10px;
}

.sidebar-filters{
  width: 263px;
  height: 62px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.dropdown-section {
  overflow: overlay;
  height: 100vh;
}

.filter-text{
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: rgba(48, 60, 73, 1);
  
}

/* .filter-dropdown{
  border: 1px solid rgba(91, 111, 133, 1);
  padding: 8px 12px;
  gap: 16px;
  width: 263px;
  height: 40px;

} */

@font-face {
  font-family: MAN EUROPE_regular;
  src: url(./ManEurope_Regular.ttf);
}

@font-face {
  font-family: MAN EUROPE_Bold;
  src: url(./MAN_Global-BoldCondensed.ttf);
}

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $backgroundColorDefault;
  .login-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background: $white;
    padding: 80px;
    .text-block {
      width: 100%;
    }
    .inputs-block {
      width: 388px;
    }
    .button-block {
      width: 100%;
      .error {
        color: $colorTextDanger;
      }
    }
  }
}



.js-plotly-plot .plotly .main-svg {
  position: absolute;
  top: 0px;
  /* left: 0px; */
  pointer-events: none;
  height: 420px;
}
.man-logovv1{
float: right;
position: relative;
top: -20px;
height: 20px;
right: 5px;
}



.Graph{
  width: 100%;
  /* overflow:overlay; */
  /* height: 57%; */

}
.card-info1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  /* box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1); */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  padding: 0px;
}

.card-info_charging {
  display: flex;
  flex-wrap: wrap;
  /* padding: 30px 30px 0; */
  justify-content: space-between;
  /* box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.1); */
  /* margin: 20px; */
  background-color: white;
  border: 10px;
}

.user-select-none svg-container{
  position: relative;
    width: 700px;
    height: 420px;
    margin-bottom: 10px;
}

.card1{
  /* border: 1px solid rgba(91, 111, 133, 1); */
  padding: 15px;
  margin: 10px;
  text-align: center;
  background-color: white;
  height: 400px;
  width: 600px;
  border: 0.5px solid rgba(91, 111, 133, 1);
  border-radius: 4px;
}
.man-logo1{
  margin-left: 4px;
}

.username{
  margin: 10px;
}

.logout{
  margin: 10px;
  display: flex;
  flex-direction: row;

}

.filter_image{
  margin-left: 10px;
}

.filters_button{
  font-family: 'MAN EUROPE CONDENSED';
  font-size: inherit;
  line-height: inherit;
  background-color: white;
  font-weight: 800;
  border: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submit_image {
  height: 23px;
  width: 30px;
  margin-bottom: 5px;
}
.graph-button {
  display: inline-block;
  /* padding: 10px 20px; */
  background-color: white;
  color: black;
  font-size: -11px;
  border: none;
  border-radius: 5px;
  margin-left: 84px;
  width: 25px;
  font-family: 'Man Europe Condensed';
  cursor: pointer;
}

/* Icon styling */
.graph-icon {
  margin-right: 10px;
  font-size: 20px;
  height: 23px;
  margin-bottom: 5px;
  width: 25px;
}

/* Hover effect */
.graph-button:hover {
  background-color: white;
}

.reload_image {
  height: 27px;
  width: 25px;
  margin-bottom: 3px;
}
/* .user-select-none svg-container{
  position: relative;
  width: 700px;
  height: 420px;
  /* padding: 10px; */
  /* border: 1px solid rgba(0,0,0,.125);
  margin: 10px; */
/* } */ 

.total_mileage_value{
  font-family: MAN EUROPE;
}


.card-info-shimmer{
  height: 420px;
  width: 700px;
  align-items: center;
  display: flex;
  justify-content: center;
} 

.card-graph-shimmer{
  height: 126vh;
  width: 74vw;
  align-items: center;
  display: flex;
  justify-content: center;

}


/* .card-info-shimmer {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 700px;
  height: 420px;
  animation: spin 2s linear infinite;
  align-items: center;
  display: flex;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */


.select__control css-13cymwt-control{
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  /* border-color: hsl(0, 0%, 80%); */
  /* border-radius: 4px; */
  /* border-style: solid;
  border-width: 1px; */
  box-sizing: border-box;
}

.css-tj5bde-Svg{
  display: inline-block;
    fill: red;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}

.card-title-text {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  color: black;
  background-color: white;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
  margin-top: 8px;
}
